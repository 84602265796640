import React, { useState } from 'react'
import "./nav.scss"
import "./mobile.scss"
import { Link } from 'react-router-dom'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const Nav = () => {

  const [click, setClick] = useState(false);

  return (
    <div className='nav'>
        <div className="menu">
            <div className="menuLeft">
              <ul className={click ? "mobile-link" : "nav-link"} onClick={()=> setClick(false)}>
               <Link to='/' className='links'><li>Home</li></Link>
               <Link to='/About' className='links'><li>About Us</li></Link>
               <Link to='/Service' className='links'><li>Our Service</li></Link>
               <Link to='/Gallery' className='links'><li>Gallery</li></Link>
               <Link to='/Contact' className='links'><li>Contact Us</li></Link>
              </ul>
              <button className='link-mobile' onClick={()=> setClick(!click)}>
                {click ? <CloseOutlinedIcon/> : <MenuOutlinedIcon/>}
              </button>
            </div>
            <div className="menuRight">
              <Link to='/' className='links icons'><FacebookRoundedIcon/></Link>
              <Link to='/' className='links icons'><InstagramIcon/></Link>
              <Link to='/' className='links icons'><TwitterIcon/></Link>
            </div>
        </div>
    </div>
  )
}

export default Nav