import React from 'react'
import "./callus.scss"
import "./mobile.scss"
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

const CallUs = () => {
  return (
    <div className='callus'>
      <span>Connect With Us</span>
      <h1>WE DRIVEN FOR GETTING RESULTS.</h1>
      <div className="callConnect">
          <div className="callL">
              <div className="callLeft"><AccessTimeOutlinedIcon className='icons'/></div>
              <div className="callRight">WE ARE OPEN MONDAY-FRIDAY</div>
              <div className="callTitle">7:00 am - 9:00 pm</div>
          </div>
          <div className="callCenter">
            <div className="callLeft"><EmailOutlinedIcon className='icons'/></div>
            <div className="callRight">HAVE A QUESTION?</div>
            <div className="callTitle">hello@shplifts.com</div>
          </div>
          <div className="callL">
            <div className="callLeft"><FmdGoodOutlinedIcon className='icons'/></div>
            <div className="callRight">NEED A REPAIR? OUR ADDRESS</div>
            <div className="callTitle">Lekki Phase One.</div>
          </div>
      </div>
    </div>
  )
}

export default CallUs