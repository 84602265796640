import React from 'react'
import "./footer.scss"
import "./mobile.scss"
import toplogo from "../../images/toplogo.png"
import p1 from "../../images/p1.jpg"
import p2 from "../../images/p2.jpg"
import p3 from "../../images/p3.jpg"
import p4 from "../../images/p4.jpg"
import p5 from "../../images/p5.jpg"
import p6 from "../../images/p6.jpg"
import { Link } from 'react-router-dom'
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <div className='footer'>
      <div className="footerT">
        <div className="footrlogo">
          <img src={toplogo} alt='' />
          <div className="logoslug">
          <span>shp lifts</span><br/>ELEVATING YOUR STYLE
          </div>
        </div>
        <div className="subs">
        SUBSCRIBE FOR LATEST UPDATES
        </div>
        <div className="subForm">
          <div className="subscribe">
              <input type='email' placeholder='Enter Email' name='email' />
              <button>Subscribe</button>
          </div>
        </div>
      </div>
      <div className="footerB">
        <div className="Fabout">
          <h3>About Us</h3>
          <p>SHP Lifts is a Nigeria based company with its major manufacturer located in Turkey, India, Italy and Australia.</p>
          <p>SHP Lifts is an elevator company providing a wide range of high quality services to a diverse client base such as top construction...</p>
          <div className="socialmedia">
              <Link to='/' className='links icons'><FacebookRoundedIcon/></Link>
              <Link to='/' className='links icons'><InstagramIcon/></Link>
              <Link to='/' className='links icons'><TwitterIcon/></Link>
          </div>
        </div>
        <div className="qlinks">
          <h3>Useful Links</h3>
          <ul>
            <Link to='/' className='links'><li>Home</li></Link>
            <Link to='/About' className='links'><li>About Us</li></Link>
            <Link to='/Service' className='links'><li>Our Service</li></Link>
            <Link to='/Gallery' className='links'><li>Gallery</li></Link>
            <Link to='/Contact' className='links'><li>Contact Us</li></Link>
          </ul>
        </div>
        <div className="contact">
          <h3>Contact Us</h3>
          <ul>
            <li><EmailIcon className='icons'/> Hello@Shplifts.Com</li>
            <li><LocalPhoneIcon className='icons'/> (+234) (+234) 911 076 2761</li>
            <li><LocationOnIcon className='icons'/> 16b Aliu Animashaun Street,Lekki Phase One Lagos</li>
          </ul>
        </div>
        <div className="Fgallery">
          <h3>Recent Work</h3>
          <img src={p1} alt='' />
          <img src={p2} alt='' />
          <img src={p3} alt='' />
          <img src={p4} alt='' />
          <img src={p5} alt='' />
          <img src={p6} alt='' />
        </div>
      </div>
      <div className="copyright">
      Copyright 2023 SHP LIFTS. All Rights Reserved.
      </div>
    </div>
  )
}

export default Footer