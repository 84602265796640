import React from 'react'
import './top.scss'
import './mobile.scss'
import toplogo from "../../images/toplogo.png"
import { Link } from 'react-router-dom'
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const Top = () => {
  return (
    <div className="top">
        <div className="container">
            <div className="top">
              <Link to='/'className='links'>
                <div className="topDiv">
                    <div className="topDivLeft"><img src={toplogo} alt='' /></div>
                    <div className="topDivRight">
                      <div className="DivRight">Shp Lifts</div>
                      <div className="DivLogoName">Elevating your style</div>
                    </div>
                </div>
              </Link>
                <div className="topDivCenter quote">
                    <div className="topDivLeft"><LocationOnIcon/></div>
                    <div className="topDivRight">
                      Our Location<br/><span>Lekki Phase One</span>
                    </div>
                </div>
                <div className="topDivCenter quote">
                    <div className="topDivLeft"><EmailIcon/></div>
                    <div className="topDivRight">
                    Send Us Mail<br/><span>hello@shplifts.com</span>
                    </div>
                </div>
                <div className="topDivCenter quote">
                    <div className="topDivLeft"><LocalPhoneIcon/></div>
                    <div className="topDivRight">
                    Call Us<br/><span>(+234) 911 076 2761</span>
                    </div>
                </div>
                <div className="topDivCenter">
                    <div className="button">
                      <Link to='/Contact' className='links'><button>Get a Quote</button></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Top