import React, { Component } from "react";
import Slider from "react-slick";
import "./feature.scss"
import "./mobile.scss"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import carCabin from '../../images/carCabin.jpg'
import Celling from '../../images/Celling.jpg'
import Handrail from '../../images/Handrail.jpg'
import COPLOP from '../../images/COPLOP.png'



export default class AutoPlay extends Component {
  
    render() {
      const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear"
      };
      return (
        <div className="feature">
          <h2>Features</h2>
          <Slider {...settings}>
          <div className="featureItems">
            <div className="items">
              <img src={carCabin} alt='' />
              <div className="itemTitle">
              Car (Cabin)
              </div>
            </div>
          </div>
          <div className="featureItems">
            <div className="items">
              <img src={Celling} alt='' />
              <div className="itemTitle">
              Celling
              </div>
            </div>
          </div>
          <div className="featureItems">
            <div className="items">
              <img src={Handrail} alt='' />
              <div className="itemTitle">
              Handrail
              </div>
            </div>
          </div>
          <div className="featureItems">
            <div className="items">
              <img src={Celling} alt='' />
              <div className="itemTitle">
              COPLOP
              </div>
            </div>
          </div>
           
          </Slider>
        </div>
      );
    }
  }





