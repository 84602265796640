import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Gallery from "./pages/gallery/Gallery";
import Service from "./pages/service/Service";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/About",
      element: <About />,
    },
    {
      path: "/Service",
      element: <Service />,
    },
    {
      path: "/Gallery",
      element: <Gallery />,
    },
    {
      path: "/Contact",
      element: <Contact />,
    },
  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
