import React from 'react'
import "./about.scss"
import "./mobile.scss"
import Top from '../../commponents/topContact/Top'
import Nav from '../../commponents/nav/Nav'
import Footer from '../../commponents/footer/Footer'

const About = () => {
  return (
    <div className='about'>
        <Top/>
        <div className="banner">
            <Nav/>
            <div className="introL">
              <h1>About <span>Us</span></h1>
              <p>we specialize in providing Sales, Service, Repair, Modernization</p>
            </div>
        </div>
        <div className="aboutBg">
            <div className="aboutLeft">
              <h1>Who we are</h1>
              <p><span>Shp Lifts</span> is a Nigeria based company with its major manufacturer located in Turkey, India, Italy and Australia.</p>
              <p>SHP Lifts is an elevator company providing a wide range of high quality services to 
                a diverse client base such as top construction companies, 
                real estate agents, commercial & private properties 
                developers, etc. We are dedicated to providing a one-stop company for all elevator needs that our clients might request.</p>
              <h3>We Specialize In</h3>
              <p>sales, installation, maintenance, repair, modernization, remodernization.</p>
              
            </div>
            <div className="aboutRight">
              <h1>Vision & Goals</h1>
              <ul>
                <li>Providing qualitative lift devices for both private and commercial society.
                SHP LIFTS LIMITED through her wealth of experience and expertise aim at delivering.</li>
                <li>Provision of friendly and Safe Lift products for the society.</li>
                <li>Ensuring affordable lift devices are readily available for all</li>
                <li>Accommodating and providing services to all class of home, business and government enterprise</li>
              </ul>
            </div>
        </div>
        <div className="footr">
          <Footer/>
        </div>
    </div>
  )
}

export default About