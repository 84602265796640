import React from 'react'
import Footer from '../../commponents/footer/Footer'
import Nav from '../../commponents/nav/Nav'
import Top from '../../commponents/topContact/Top'
import "./gallery.scss"
import "./mobile.scss"
import p1 from "../../images/p1.jpg"
import p2 from "../../images/p2.jpg"
import p3 from "../../images/p3.jpg"
import p4 from "../../images/p4.jpg"
import p5 from "../../images/p5.jpg"
import p6 from "../../images/p6.jpg"
import p7 from "../../images/p7.jpg"
import shlift from "../../images/shlift.mp4"
import shpmove from "../../images/shpmove.mp4"
import s1 from "../../images/s1.jpg";
import s2 from "../../images/s2.jpg";
import s3 from "../../images/s3.jpg";
import s4 from "../../images/s4.jpg";
import s5 from "../../images/s5.jpg";

const Gallery = () => {

    const pics = [
      {
        id: "1",
        imgs: p1,
      },
      {
        id: "2",
        imgs: p2,
      },
      {
        id: "3",
        imgs: p3,
      },
      {
        id: "4",
        imgs: p4,
      },
      {
        id: "5",
        imgs: p5,
      },
      {
        id: "6",
        imgs: p6,
      },
      {
        id: "7",
        imgs: p7,
      },
      {
        id: "8",
        imgs: s1,
      },
      {
        id: "9",
        imgs: s2,
      },
      {
        id: "10",
        imgs: s3,
      },
      {
        id: "11",
        imgs: s4,
      },
      {
        id: "12",
        imgs: s5,
      },
    ];

    return (
        <div className="gallery">
            <Top/>
            <div className="banner">
                <Nav/>
                <div className="introL">
                <h1>Our <span>WORKs</span></h1>
                <p>we specialize in providing Sales, Service, Repair, Modernization</p>
                </div>
            </div>
            <div className="galleryvideos">
              <video width="300px" height="400" controls className='vde'>
                <source src={shpmove} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
              </video>
              <video width="300px" height="400" controls className='vde'>
                <source src={shlift} type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
              </video>
            </div>
            <div className="pics">
                {pics.map((pics)=>(
                    <div className="pic" key={pics.id}>
                    <img src={pics.imgs} alt='' />
                    </div>
                ))}
            </div>
            <div className="footr">
            <Footer/>
            </div>
        </div>
    )
}

export default Gallery