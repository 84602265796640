import React from 'react'
import "./services.scss"
import "./mobile.scss"
//import { Link } from 'react-router-dom'
import SHPBrochure from '../../images/SHPBrochure.pdf'
import s6 from "../../images/s6.jpg"
import s8 from "../../images/s8.jpg";
import s15 from "../../images/s15.jpg";
import s30 from "../../images/s30.jpg";
import s2 from "../../images/s2.jpg";
import s19 from "../../images/s19.jpg";
import s23 from "../../images/s23.jpg";
import s3 from "../../images/s3.jpg";

const Services = () => {

const services = [
  {
    id: "1",
    img: s8,
    title: "Square glass view lift",
  },
  {
    id: "2",
    img: s6,
    title: "Steel shaft home lift",
  },
  {
    id: "3",
    img: s15,
    title: "Round silver panoramic home lift",
  },
  {
    id: "4",
    img: s30,
    title: "Panoramic home lift",
  },
  {
    id: "5",
    img: s2,
    title: "Dumbwaiter stainless shaft",
  },
  {
    id: "6",
    img: s19,
    title: "Round panoramic view home lift",
  },
  {
    id: "7",
    img: s23,
    title: "Glass view home lift",
  },
  {
    id: "8",
    img: s3,
    title: "Dumbwaiter G+1",
  },
];

  return (
    <div className="services">
      <div className="quality">
        <span>We specialize in</span>
        <h1>QUALITY SERVICES</h1>
      </div>
      <div className="serviceTitile">
        {services.map((service) => (
          <div className="cards" key={service.id}>
            <div className="imagF">
              <img src={service.img} atl="" />
            </div>
            <div className="cardT">
              <h2>{service.title}</h2>
              <p>{service.details}</p>
              <button>
                <a href={SHPBrochure} download="BROCHURE" className="links">
                  VIEW BROCHURE
                </a>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services