import React from 'react'
import "./contact.scss"
import "./mobile.scss"
import Top from '../../commponents/topContact/Top'
import Nav from '../../commponents/nav/Nav'
import Footer from '../../commponents/footer/Footer'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

const Contact = () => {
  return (
    <div className='contact'>
        <Top/>
        <div className="banner">
            <Nav/>
            <div className="introL">
              <h1>Contact <span>Us</span></h1>
              <p>we specialize in providing Sales, Service, Repair, Modernization</p>
            </div>
        </div>
        <div className="contactbg">
            <div className="contactmap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6966348198785!2d3.4555122783812084!3d6.433000676948234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf5a9fbd17653%3A0xd369a3787401cd61!2s16b%20Aliu%20Animashaun%20Ave%2C%20Lekki%20Phase%20I%20106104%2C%20Lekki%2C%20Lagos!5e0!3m2!1sen!2sng!4v1681821697465!5m2!1sen!2sng" 
                width="100%" 
                height="500" 
                style={{ border: "0" }} 
                allowfullscreen="" 
                loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
            <div className="contactForm">
                <div className="contactnumber">
                    <div className="contactinfor">
                        <LocalPhoneIcon className="cicon" /> (+234) 911 076 2761
                    </div>
                    <div className="contactinfor">
                        <EmailIcon className="cicon" /> Hello@Shplifts.Com
                    </div>
                </div>
                <div className="cL">
                    <div className="cLtxt">
                        <div className="txtFeild">
                            <input type='text' name='fname' placeholder='First Name' />
                        </div>
                        <div className="txtFeild">
                        <input type='text' name='fname' placeholder='Last Name' />
                        </div>
                    </div>
                    <div className="cLtxt">
                        <div className="txtFeild">
                            <input type='text' name='lname' placeholder='Contact Number' />
                        </div>
                        <div className="txtFeild">
                        <input type='email' name='email' placeholder='Email Address' />
                        </div>
                    </div>
                    <div className="cLtxt">
                        <div className="txtFeild">
                            <input type='text' name='subject' placeholder='Subject' />
                        </div>
                    </div>
                    <div className="cLtxt">
                        <div className="txtFeild">
                            <textarea name='' rows='' placeholder='Message here...'></textarea>
                        </div>
                    </div>
                    <button>Send Message</button>
                </div>
            </div>
        </div>
        <div className="footr">
          <Footer/>
        </div>
    </div>
  )
}

export default Contact