import React from 'react'
import "./aboutUs.scss"
import "./mobile.scss"
import Elevatorssola from "../../images/Elevatorssola.jpg"
import { Link } from 'react-router-dom'

const AboutUs = () => {
  return (
    <div className="aboutUs">
      <div className="Aleft">
        <img src={Elevatorssola} alt="" />
        <div className="leftg">Leading in HIGH QUALITY ELEVETOR</div>
      </div>
      <div className="Aright">
        <span>Shp Lifts</span>
        <h2>Who we are</h2>
        <p>
          SHP Lifts is a Nigeria based company with its major manufacturer
          located in Turkey, India, Italy and Australia.
        </p>
        <p>
          SHP Lifts is an elevator company providing a wide range of high
          quality services to a diverse client base such as top construction
          companies, real estate agents, commercial & private properties
          developers, etc. We are dedicated to providing a one-stop company for
          all elevator needs that our clients might request.
        </p>
        <div className="button">
          <button>
            <Link to="/About" className="links">
              Read More
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AboutUs