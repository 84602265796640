import React from 'react'
import './home.scss'
import './mobile.scss'
import Top from '../../commponents/topContact/Top'
import Nav from '../../commponents/nav/Nav'
import { Link } from 'react-router-dom'
import Services from '../../commponents/services/Services'
import AboutUs from '../../commponents/about/AboutUs'
import CallUs from '../../commponents/callus/CallUs'
import Footer from '../../commponents/footer/Footer'
import Feature from '../../commponents/feature/Feature'
//import shpLifts from '../../images/shpLifts.mp4'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import SHPBrochure from '../../images/SHPBrochure.pdf'

const Home = () => {
  return (
    <div className="home">
      <Top />
      <div className="banner">
        <Nav />
        <div className="introBanner">
          <div className="introL">
            <h1>
              HIGH QUALITY <span>ELEVATOR</span>
            </h1>
            <p>
              we specialize in providing Sales, Service, Repair, Modernization
            </p>
            <div className="button">
              <button>
                <a
                  href={SHPBrochure}
                  download="DOWNLOAD BROCHURE"
                  className="links"
                >
                  DOWNLOAD BROCHURE
                  <DownloadOutlinedIcon className="icons" />
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="services">
        <Services />
      </div>
      <div className="aboutus">
        <AboutUs />
      </div>
      <div className="callus">
        <CallUs />
      </div>
      <div className="footr">
        <Footer />
      </div>
    </div>
  );
}

export default Home