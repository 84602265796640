import React from 'react'
import "./service.scss"
import "./mobile.scss"
import Top from '../../commponents/topContact/Top'
import Nav from '../../commponents/nav/Nav'
import Footer from '../../commponents/footer/Footer'
import Escalator from '../../images/Escalator.jpg'
import AutoElevator from '../../images/AutoElevator.jpg'
import HomeElevator from '../../images/HomeElevator.jpg'
import HospitalBed from '../../images/HospitalBed.jpg'
import Panoramic from '../../images/Panoramic.jpg'
import Passager from '../../images/Passager.png'
import Scissor from '../../images/Scissor.jpg'
import Freight from '../../images/Freight.jpeg'
import s1 from "../../images/s1.jpg";
import s2 from "../../images/s2.jpg";
import s3 from "../../images/s3.jpg";
import s4 from "../../images/s4.jpg";
import s5 from "../../images/s5.jpg";
import s6 from "../../images/s6.jpg";
import s7 from "../../images/s7.jpg";
import s8 from "../../images/s8.jpg";
import s9 from "../../images/s9.jpg";
import s10 from "../../images/s10.jpg";
import s11 from "../../images/s11.jpg";
import s12 from "../../images/s12.jpg";
import s13 from "../../images/s13.jpg";
import s14 from "../../images/s14.jpg";
import s15 from "../../images/s15.jpg";
import s16 from "../../images/s16.jpg";
import s17 from "../../images/s17.jpg";
import s18 from "../../images/s18.jpg";
import s19 from "../../images/s19.jpg";
import s20 from "../../images/s20.jpg";
import s21 from "../../images/s21.jpg";
import s22 from "../../images/s22.jpg";
import s23 from "../../images/s23.jpg";
import s24 from "../../images/s24.jpg";
import s30 from "../../images/s30.jpg";
import s31 from "../../images/s31.jpg";

const Service = () => {

    const service = [
      {
        id: "1",
        img: Passager,
        title: "Passager Elevator (MR/MRS)",
      },
      {
        id: "2",
        img: HomeElevator,
        title: "Home Elevator",
      },
      {
        id: "3",
        img: Freight,
        title: "Freight / Goods Elevator",
      },
      {
        id: "4",
        img: Panoramic,
        title: "Panoramic / Observation Elevator",
      },
      {
        id: "5",
        img: HospitalBed,
        title: "Bed / Hospital Elevator",
      },
      {
        id: "6",
        img: Scissor,
        title: "Scissor Lift",
      },
      {
        id: "7",
        img: AutoElevator,
        title: "Car / Vehicle Elevator",
      },
      {
        id: "8",
        img: Escalator,
        title: "Escalator",
      },
      {
        id: "9",
        img: s2,
        title: "Dumbwaiter stainless shaft",
      },
      {
        id: "10",
        img: s3,
        title: "Dumbwaiter G+1",
      },
      {
        id: "11",
        img: s4,
        title: "Warehouse dumbwaiter",
      },
      {
        id: "12",
        img: s5,
        title: "Dumbwaiter with steel and marble cover",
      },
      {
        id: "13",
        img: s6,
        title: "Steel shaft home lift",
      },
      {
        id: "14",
        img: s7,
        title: "Lift doors",
      },
      {
        id: "15",
        img: s8,
        title: "Square glass view lift",
      },
      {
        id: "16",
        img: s9,
        title: "Square shape home lift",
      },
      {
        id: "17",
        img: s10,
        title: "Black glass view home lift",
      },
      {
        id: "18",
        img: s11,
        title: "Glass square home lift",
      },
      {
        id: "19",
        img: s12,
        title: "Outdoor lift",
      },
      {
        id: "20",
        img: s13,
        title: "Outdoor lift",
      },
      {
        id: "21",
        img: s14,
        title: "Outdoor lift with shaft",
      },
      {
        id: "22",
        img: s15,
        title: "Round silver panoramic home lift",
      },
      {
        id: "23",
        img: s16,
        title: "Commercial elevator",
      },
      {
        id: "24",
        img: s18,
        title: "Glass view home lift",
      },
      {
        id: "25",
        img: s19,
        title: "Round panoramic view home lift",
      },
      {
        id: "26",
        img: s20,
        title: "Steel shaft home lift",
      },
      {
        id: "27",
        img: s21,
        title: "Escalator",
      },
      {
        id: "28",
        img: s22,
        title: "Outdoor lift",
      },
      {
        id: "29",
        img: s23,
        title: "Glass view home lift",
      },
      {
        id: "30",
        img: s30,
        title: "Panoramic home lift",
      },
      {
        id: "31",
        img: s31,
        title: "Panoramic home lift",
      },
    ];

    return (
        <div className="service">
            <Top/>
            <div className="banner">
                <Nav/>
                <div className="introL">
                <h1>Our <span>Services</span></h1>
                <p>we specialize in providing Sales, Service, Repair, Modernization</p>
                </div>
            </div>
            <div className="servicesFram">
                <h1>We Specialize In</h1>
                <div className="serviceItems">
                    {service.map((service)=>(
                        <div className="items" key={service.id}>
                            <img src={service.img} alt='' />
                            <div className="itemTitle">
                                {service.title}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="serviceFeature">
                <div className="fLeft">
                    <table>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="fRight"></div>
            </div>
            <div className="footr">
            <Footer/>
            </div>
        </div>
    )
}

export default Service